import lodash from "lodash";
import { tableActions } from "../../types/types";
import {parseColumnValue} from "../../../helper/parseColumn"
const initialStatus = {};
const tableInitialState = {
  requesting: false,
  removingRecord: true,
  hasError: false,
  error: null,
  columns: [],
  detailedView: [],
  data: [],
  count: 0,
  refetchRecords: false
};

export const getCSVParseData = (data,parseColoumn,uniqueKeyForCsv) =>{
   let jsonArr = [];
  // let uniqueKey = ''
   parseColoumn.forEach(keyObject =>{
  //  uniqueKey = keyObject.displayName;
   
    data.forEach(dataObj =>{
      if(keyObject.parseWith){
        let appendValue = '';
        keyObject.parseWith.forEach(appendData=>{
          appendValue = appendValue +' '+lodash.get(dataObj, appendData, "-") || "-"
        })
        dataObj[keyObject.displayName] = appendValue
      }else{
        dataObj[keyObject.displayName] = lodash.get(dataObj, keyObject.columnName, "-") || "-";
      }
      if(keyObject.parseAs){
        dataObj[keyObject.displayName] = parseColumnValue(dataObj[keyObject.displayName],keyObject.parseAs);
      }
        jsonArr.push(dataObj);
    })
   })
   jsonArr = lodash.uniqBy(jsonArr, function(e) {
    return e[uniqueKeyForCsv];
  });
    return jsonArr
}

const markNoErrors = {
  hasError: false,
  error: null,
  removingRecord: false,
  requesting: false,
  refetchRecords: false
};

const table = (state = initialStatus, action) => {
  switch (action.type) {
    case tableActions.TBL_REGISTER_TABLE:
      return {
        ...state,
        [action.tableName]: {
          ...tableInitialState,
          columns: action.columns,
          detailedView: action.detailView
        }
      };

    case tableActions.TBL_REQUEST_DATA:
      return {
        ...state,
        [action.tableName]: {
          ...state[action.tableName],
          ...markNoErrors,
          requesting: true,
          data: []
        }
      };

    case tableActions.TBL_REQUEST_COMPLETE:
      return {
        ...state,
        [action.tableName]: {
          ...state[action.tableName],
          ...markNoErrors,
          data: action.data,
          count: action.count
        }
      };

    case tableActions.TBL_REMOVE_RECORD:
      return {
        ...state,
        [action.tableName]: {
          ...state[action.tableName],
          ...markNoErrors,
          removingRecord: true
        }
      };

    case tableActions.TBL_RELOAD:
    case tableActions.TBL_REMOVE_RECORD_COMPLETE:
      let onDeleteJson = {
        ...state,
        [action.tableName]: {
          ...state[action.tableName],
          ...markNoErrors,
          refetchRecords: true
        }
      };
      if (action.ondeleteReloadTable) {
        onDeleteJson[action.ondeleteReloadTable] = {
          ...state[action.ondeleteReloadTable],
          ...markNoErrors,
          refetchRecords: true
        };
      }
      return onDeleteJson;

    case tableActions.TBL_ERROR:
      return {
        ...state,
        [action.tableName]: {
          ...state[action.tableName],
          ...markNoErrors,
          hasError: true,
          error: action.error
        }
      };

    default:
      return state;
  }
};

export default table;
