import { settingActions } from "../../types/types";
import { updateWith, constant } from "lodash";
const initialStatus = {};
const moduleInitialState = {
  requesting: false,
  hasError: false,
  error: null,
  isNewRecord: true,
  configuration: {},
  initial: {}
};

const markNoErrors = {
  hasError: false,
  error: null
};

const settings = (state = initialStatus, action) => {
  switch (action.type) {
    case settingActions.CONF_REGISTER_MODULE:
      let conf = {};
      action.fields.forEach(data => {
        if (data.range) {
          data.range.forEach(timeRangeData => {
            updateWith(
              conf,
              timeRangeData.name,
              constant(
                timeRangeData.hasOwnProperty("value") ? timeRangeData.value : ""
              ),
              Object
            );
          });
        } else {
          updateWith(
            conf,
            data.name,
            constant(data.hasOwnProperty("value") ? data.value : ""),
            Object
          );
        }
      });
      return {
        ...state,
        [action.module]: {
          ...moduleInitialState,
          configuration: conf,
          initial: { ...conf }
        }
      };

    case settingActions.CONF_REQUEST:
      return {
        ...state,
        [action.module]: {
          ...markNoErrors,
          ...state[action.module],
          requesting: true
        }
      };

    case settingActions.CONF_ERROR:
      return {
        ...state,
        [action.module]: {
          ...state[action.module],
          requesting: false,
          hasError: action.strictError,
          error: action.error
        }
      };

    case settingActions.CONF_REQUEST_COMPLETE:
      return {
        ...state,
        [action.module]: {
          ...state[action.module],
          ...markNoErrors,
          requesting: false,
          configuration: {
            ...((action.reset && state[action.module].initial) || {
              ...state[action.module].configuration,
              ...action.payload
            })
          }
        }
      };

    case settingActions.CONF_HANDLE_CHANGE:
      let { configuration } = state[action.module] || {};

      updateWith(
        configuration,
        action.fieldName,
        constant(action.value),
        Object
      );
      return {
        ...state,
        [action.module]: {
          ...state[action.module],
          requesting: false,
          configuration,
          ...markNoErrors
        }
      };
    default:
      return state;
  }
};

export default settings;
