import { tableActions } from "../../types/types";
const initialStatus = {
  isLoading: false,
  tableList: [],
  count: 0,
  slaTitle: "",
  page: "1"
};

const restaurantLocation = (state = initialStatus, { type, payload }) => {
  switch (type) {
    case tableActions.REQUEST_INITIATE:
      return { isLoading: true };
    case tableActions.GET_TABLE_SUCCESS:
      if (payload.data.result) {
        return {
          isLoading: false,
          tableList: payload.data.result.levels,
          slaTitle: payload.data.result.title
        };
      }
      return {
        isLoading: false,
        tableList: payload.data.results,
        count: payload.data.count,
        page: payload.page
      };
    case tableActions.UPDATE_SLA_LEVEL:
      return { tableList: payload.data };
    case tableActions.REMOVE_TABLE_DATA:
      return { tableList: [] };
    case tableActions.UPDATE_PAGE:
      return { page: payload.pageNo };
    case tableActions.UPDATE_SLA_GROUP_LIST:
      return { ...state, page: payload.page, tableList: payload.data };
    case tableActions.UPDATE_SLA_TITLE:
      return { ...state, slaTitle: payload.title };
    case tableActions.REMOVE_SLA_TITLE:
      return { ...state, slaTitle: "" };
    default:
      return state;
  }
};

export default restaurantLocation;
