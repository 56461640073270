import { combineReducers } from "redux";
import user from "./auth/user";
import forms from "./forms";
import restaurantLocation from "./organization/location";
import customizer from "./customizer/";
import configuration from "./configuration/configuration";
import tableActions from "./tableConfigurations/tableActions";
import dataTables from "./tables";
import fetch from "./fetch";
import tickets from "./tickets";

import { reducer as toastrReducer } from "react-redux-toastr";

const rootReducer = combineReducers({
  toastr: toastrReducer,
  user: user,
  restaurantLocation: restaurantLocation,
  customizer: customizer,
  configuration: configuration,
  tableConfiguration: tableActions,
  forms,
  dataTables,
  fetch,
  ticketUpdates: tickets
});

export default rootReducer;
